import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const Company = ({ location }) => (
  <Layout location={location}>
    <Seo title="企業情報" description="株式会社ファーストの企業情報" />
    <div className="container">
      <StaticImage
        src="../images/logo-gray.png"
        placeholder="none"
        quality={90}
        alt="Logo"
        width={50}
        layout="fixed"
        className="title-logo"
      />
      <h3 className="title is-4" style={{ color: "gray" }}>
        企業情報
      </h3>
      <section className="section">
        <h4 className="title is-4">企業概要</h4>
        <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
          <table className="table is-fullwidth is-bordered table-company">
            <tbody>
              <tr>
                <th>商号</th>
                <td>株式会社ファースト（First.Co., Ltd.）</td>
              </tr>
              <tr>
                <th>設立</th>
                <td>2020年9月</td>
              </tr>
              <tr>
                <th>代表取締役</th>
                <td>宮島　哲弘</td>
              </tr>
              <tr>
                <th>営業時間</th>
                <td>
                  平日 10:00 ~ 19:00 <br />
                  土・日・祝 ※ご希望により対応可
                </td>
              </tr>
              <tr>
                <th>TEL</th>
                <td>011-596-7480</td>
              </tr>
              <tr>
                <th>FAX</th>
                <td>011-596-7490</td>
              </tr>
              <tr>
                <th>事業内容</th>
                <td>
                  不動産賃貸・売買仲介/買取・販売/不動産管理/資産運用/
                  不動産コンサルティング <br />
                  各種リフォーム/保険代理店など
                </td>
              </tr>
              <tr>
                <th>宅地建物取引業者免許</th>
                <td>北海道知事石狩（1）第9013号</td>
              </tr>
              <tr>
                <th>加盟協会</th>
                <td>
                  公益社団法人　全日本不動産協会
                  <br />
                  公益社団法人　不動産保証協会
                  <br />
                  札幌商工会議所
                  <br />
                  北海道宅地建物取引業協会会員
                  <br />
                </td>
              </tr>
              <tr>
                <th>取引金融機関</th>
                <td>
                  北洋銀行　東屯田支店/北海道銀行　南1条支店
                  <br />
                  札幌中央信用組合　山鼻支店他
                </td>
              </tr>
              <tr>
                <th>主要取引先</th>
                <td>
                  (株)常口アトム／(株)ビッグ／(株)エイブル
                  ／アパマンショップＦＣ各社
                  <br />
                  その他賃貸仲介業者各社 <br />
                  (株)ライトニング／(株)スリーアール／(株)アルド
                  <br />
                  その他売買仲介業者各社 <br />
                  (株)リスモハウス／池田工業(株)／ライトテック／高橋産業(株)
                  あいおい同和損害保険(株) ／東日本少額短期保険(株) <br />
                  全保連(株)／日本賃貸保証(株)／(株)オリコフォレントインシュア
                  <br />
                  その他保証会社各社
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="section">
        <h4 className="title is-4">所在地</h4>
        <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
          <table className="table is-fullwidth is-bordered table-company">
            <tr>
              <th>住所</th>
              <td>
                〒064-0808　札幌市中央区南8条西7丁目1782-23　KSコート102号室
              </td>
            </tr>
            <tr>
              <th>交通</th>
              <td>
                ・札幌市営地下鉄 南北線 中島公園駅から徒歩7分
                <br />
                ・札幌市電 山鼻9条駅から徒歩2分
              </td>
            </tr>
            <tr>
              <th>地図</th>
              <td>
                <div className="iframe-wrapper">
                  <iframe
                    title="所在地"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1457.8039468706402!2d141.34775793914267!3d43.04968085284174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b2989a03b5d67%3A0x68fc57b3d50c7b6f!2z44CSMDY0LTA4MDgg5YyX5rW36YGT5pyt5bmM5biC5Lit5aSu5Yy65Y2X77yY5p2h6KW_77yX5LiB55uu77yR77yX77yY77yS4oiS77yS77yTIO-8q--9k--8o--8r--8te-8su-8tA!5e0!3m2!1sja!2sjp!4v1695009979888!5m2!1sja!2sjp"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </div>
  </Layout>
)

export default Company
